import React from 'react'
import Layout from '../components/layout/Layout'

const NotFoundPage = () => (
    <Layout>
        <div className="content">
            <section className="section section--gradient">
                <div className="container">
                    <div style={{ marginTop: '2rem'}}>
                        <h1>Page introuvable</h1>
                        <p>Cette page n'existe pas ... dommage !</p>
                    </div>
                </div>
            </section>
        </div>
    </Layout>
)

export default NotFoundPage
